/* eslint-disable mastery/known-imports */
import classNames from 'classnames';
import { isEqual, memoize, noop } from 'lodash-es';
import { nanoid } from 'nanoid';
import { Component } from 'react';
import { Button } from './button';
import { CheckModel, CheckModelType, NodeModel } from './nodeModel';
import { Icons as IconsShape } from './shapes/iconsShape';
import { LanguageShape } from './shapes/languageShape';
import { NodeShape } from './shapes/nodeShape';
import { TreeNode } from './treeNode';

export interface CheckboxTreeProps {
  nodes: NodeShape<anyOk>[];
  checkModel: CheckModelType;
  checked: string[];
  direction: string;
  disabled: boolean;
  expandDisabled: boolean;
  expandOnClick: boolean;
  expanded: string[];
  icons: IconsShape;
  iconsClass: string;
  id: string;
  lang: LanguageShape;
  name: string;
  nameAsArray: boolean;
  nativeCheckboxes: boolean;
  noCascade: boolean;
  onlyLeafCheckboxes: boolean;
  optimisticToggle: boolean;
  showExpandAll: boolean;
  showNodeIcon: boolean;
  showNodeTitle: boolean;
  onCheck: (checked: string[], nodeInfo?: anyOk) => void;
  onClick: (value: anyOk) => void;
  onExpand: (expanded: string[], nodeInfo?: anyOk) => void;

  fsName?: string;
  fsType?: string;
  fsParent?: string;
  fsCheckboxElement?: string;
  fsItemElement?: string;
}

interface StateType {
  id: string;
  model: NodeModel;
  prevProps: CheckboxTreeProps;
}

export class CheckboxTree extends Component<CheckboxTreeProps, StateType> {
  static defaultProps = {
    checkModel: CheckModel.LEAF,
    checked: [],
    direction: 'ltr',
    disabled: false,
    expandDisabled: false,
    expandOnClick: false,
    expanded: [],
    icons: {
      check: <span className="rct-icon rct-icon-check" />,
      uncheck: <span className="rct-icon rct-icon-uncheck" />,
      halfCheck: <span className="rct-icon rct-icon-half-check" />,
      expandClose: <span className="rct-icon rct-icon-expand-close" />,
      expandOpen: <span className="rct-icon rct-icon-expand-open" />,
      expandAll: <span className="rct-icon rct-icon-expand-all" />,
      collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
      parentClose: <span className="rct-icon rct-icon-parent-close" />,
      parentOpen: <span className="rct-icon rct-icon-parent-open" />,
      leaf: <span className="rct-icon rct-icon-leaf" />,
    },
    iconsClass: 'fa4',
    id: null,
    lang: {
      collapseAll: 'Collapse all',
      expandAll: 'Expand all',
      toggle: 'Toggle',
    },
    name: undefined,
    nameAsArray: false,
    nativeCheckboxes: false,
    noCascade: false,
    onlyLeafCheckboxes: false,
    optimisticToggle: true,
    showExpandAll: false,
    showNodeIcon: true,
    showNodeTitle: false,
    onCheck: noop,
    onClick: null,
    onExpand: noop,
  };

  combineMemorized: (icons1: IconsShape, icons2: IconsShape) => IconsShape;

  constructor(props: CheckboxTreeProps) {
    super(props);

    const model: NodeModel = new NodeModel(props);
    model.flattenNodes(props.nodes);
    model.deserializeLists({
      checked: props.checked,
      expanded: props.expanded,
    });

    this.state = {
      id: props.id || `rct-${nanoid()}`,
      model,
      prevProps: props,
    };

    this.onCheck = this.onCheck.bind(this);
    this.onExpand = this.onExpand.bind(this);
    this.onNodeClick = this.onNodeClick.bind(this);
    this.onExpandAll = this.onExpandAll.bind(this);
    this.onCollapseAll = this.onCollapseAll.bind(this);

    this.combineMemorized = memoize((icons1, icons2) => ({
      ...icons1,
      ...icons2,
    })).bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  static getDerivedStateFromProps(
    newProps: CheckboxTreeProps,
    prevState: StateType
  ): StateType {
    const { model, prevProps } = prevState;
    const { disabled, id, nodes } = newProps;
    let newState = { ...prevState, prevProps: newProps };

    // Apply new properties to model
    model.setProps(newProps);

    // Since flattening nodes is an expensive task, only update when there is a node change
    if (!isEqual(prevProps.nodes, nodes) || prevProps.disabled !== disabled) {
      model.reset();
      model.flattenNodes(nodes);
    }

    if (id !== null) {
      newState = { ...newState, id };
    }

    model.deserializeLists({
      checked: newProps.checked,
      expanded: newProps.expanded,
    });

    return newState;
  }

  onCheck(nodeInfo: fixMe): void {
    const { checkModel, noCascade, onCheck } = this.props;
    const model = this.state.model.clone();
    const node = model.getNode(nodeInfo.value);

    model.toggleChecked(nodeInfo, nodeInfo.checked, checkModel, noCascade);
    onCheck?.(model.serializeList('checked'), { ...node, ...nodeInfo });
  }

  onExpand(nodeInfo: fixMe): void {
    const { onExpand } = this.props;
    const model = this.state.model.clone();
    const node = model.getNode(nodeInfo.value);

    model.toggleNode(nodeInfo.value, 'expanded', nodeInfo.expanded);
    onExpand?.(model.serializeList('expanded'), { ...node, ...nodeInfo });
  }

  onNodeClick(nodeInfo: fixMe): void {
    const { onClick } = this.props;
    const { model } = this.state;
    const node = model.getNode(nodeInfo.value);

    onClick?.({ ...node, ...nodeInfo });
  }

  onExpandAll(): void {
    this.expandAllNodes();
  }

  onCollapseAll(): void {
    this.expandAllNodes(false);
  }

  expandAllNodes(expand = true): void {
    const { onExpand } = this.props;

    onExpand(
      this.state.model.clone().expandAllNodes(expand).serializeList('expanded'),
      {}
    );
  }

  determineShallowCheckState(node: NodeShape, noCascade: boolean): number {
    const flatNode = this.state?.model?.getNode(node.value.toString());

    if (flatNode?.isLeaf || noCascade || node?.children?.length === 0) {
      // Note that an empty parent node tracks its own state
      return flatNode?.checked ? 1 : 0;
    }

    if (this.isEveryChildChecked(node)) {
      return 1;
    }

    if (this.isSomeChildChecked(node)) {
      return 2;
    }

    return 0;
  }

  isEveryChildChecked(node: NodeShape): boolean {
    return (
      node?.children?.every(
        (child) =>
          this.state?.model?.getNode(child?.value.toString())?.checkState === 1
      ) ?? false
    );
  }

  isSomeChildChecked(node: NodeShape): boolean {
    return (
      node?.children?.some(
        (child) =>
          this.state?.model?.getNode(child?.value.toString())?.checkState > 0
      ) ?? false
    );
  }

  renderTreeNodes(
    nodes: fixMe,
    parent: Record<string, anyOk> = {}
  ): JSX.Element | null {
    const {
      expandDisabled,
      expandOnClick,
      icons,
      lang,
      noCascade,
      onClick,
      onlyLeafCheckboxes,
      optimisticToggle,
      showNodeTitle,
      showNodeIcon,
      fsName,
      fsType,
      fsParent,
      fsCheckboxElement,
      fsItemElement,
    } = this.props;
    const { id, model } = this.state;
    const { icons: defaultIcons } = CheckboxTree.defaultProps;

    const treeNodes = nodes?.map((node: fixMe) => {
      const key = node?.value;
      const flatNode = model.getNode(node?.value.toString());
      const children = flatNode?.isParent
        ? this.renderTreeNodes(node?.children || [], node)
        : null;

      // Determine the check state after all children check states have been determined
      // This is done during rendering as to avoid an additional loop during the
      // deserialization of the `checked` property
      flatNode.checkState = this.determineShallowCheckState(node, noCascade);

      // Show checkbox only if this is a leaf node or showCheckbox is true
      const showCheckbox = onlyLeafCheckboxes
        ? flatNode.isLeaf
        : flatNode.showCheckbox;

      // Render only if parent is expanded or if there is no root parent
      const parentExpanded = parent?.value
        ? model.getNode(parent?.value)?.expanded
        : true;

      if (!parentExpanded) {
        return null;
      }

      return (
        <TreeNode
          key={key}
          checked={flatNode?.checkState}
          className={node?.className}
          disabled={flatNode?.disabled}
          expandDisabled={expandDisabled}
          expandOnClick={expandOnClick}
          expanded={flatNode?.expanded}
          icon={node?.icon}
          icons={this.combineMemorized(defaultIcons, icons)}
          label={node?.label}
          lang={lang}
          optimisticToggle={optimisticToggle}
          isLeaf={flatNode?.isLeaf}
          isParent={flatNode?.isParent}
          showCheckbox={showCheckbox}
          showNodeIcon={showNodeIcon}
          title={showNodeTitle ? node?.title || node?.label : node?.title}
          treeId={id}
          value={node?.value}
          onCheck={this.onCheck}
          onClick={typeof onClick === 'function' ? this.onNodeClick : undefined}
          onExpand={this.onExpand}
          fsName={fsName}
          fsType={fsType}
          fsParent={fsParent}
          fsCheckboxElement={fsCheckboxElement}
          fsItemElement={fsItemElement}
        >
          {children}
        </TreeNode>
      );
    });

    return <ol>{treeNodes}</ol>;
  }

  renderExpandAll(): JSX.Element | null {
    const {
      icons: { expandAll, collapseAll },
      lang,
      showExpandAll,
    } = this.props;

    if (!showExpandAll) {
      return null;
    }

    return (
      <div className="rct-options">
        <Button
          className="rct-option rct-option-expand-all"
          title={lang.expandAll}
          onClick={this.onExpandAll}
        >
          {expandAll}
        </Button>
        <Button
          className="rct-option rct-option-collapse-all"
          title={lang.collapseAll}
          onClick={this.onCollapseAll}
        >
          {collapseAll}
        </Button>
      </div>
    );
  }

  renderHiddenInput(): JSX.Element | null {
    const { name, nameAsArray } = this.props;

    if (name === undefined) {
      return null;
    }

    if (nameAsArray) {
      return this.renderArrayHiddenInput();
    }

    return this.renderJoinedHiddenInput();
  }

  renderArrayHiddenInput(): JSX.Element {
    const { checked, name: inputName } = this.props;

    return (
      <>
        {checked?.map((value: anyOk) => {
          const name = `${inputName}[]`;

          return <input key={value} name={name} type="hidden" value={value} />;
        })}
      </>
    );
  }

  renderJoinedHiddenInput(): JSX.Element {
    const { checked, name } = this.props;
    const inputValue = checked.join(',');

    return <input name={name} type="hidden" value={inputValue} />;
  }

  render(): JSX.Element {
    const { direction, disabled, iconsClass, nodes, nativeCheckboxes } =
      this.props;
    const { id } = this.state;
    const treeNodes = this.renderTreeNodes(nodes);

    const className = classNames({
      'react-checkbox-tree': true,
      'rct-disabled': disabled,
      [`rct-icons-${iconsClass}`]: true,
      'rct-native-display': nativeCheckboxes,
      'rct-direction-rtl': direction === 'rtl',
    });

    return (
      <div className={className} id={id}>
        {this.renderExpandAll()}
        {this.renderHiddenInput()}
        {treeNodes}
      </div>
    );
  }
}
