import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type AssetDriverCurrentHosEventPayloadFragment = (
  { readonly __typename?: 'AssetDriverCurrentHosEvent' }
  & Pick<TP.AssetDriverCurrentHosEvent, 'id' | 'driverId'>
);

export const AssetDriverCurrentHosEventPayloadFragmentDoc = gql`
    fragment AssetDriverCurrentHosEventPayload on AssetDriverCurrentHosEvent {
  id
  driverId
}
    `;