import { ENV_VARS, MODE } from '@env';
import { win } from '@utils/win';
export const BUILD_VERSION = ENV_VARS.VITE_BUILD_VERSION;

const devDomains = ['connect-carrier.dev.hub.mastermindtms.com'] as const;

const testDomains = ['connect-carrier.test.hub.mastermindtms.com'] as const;

const uatDomains = [
  'connect-carrier.uat-c.hub.mastermindtms.com',
  'connect-carrier.uat-e.hub.mastermindtms.com',
];

const prodDomains = ['connect-carrier.hub.mastermindtms.com'] as const;

let hostname: fixMe = '';
try {
  hostname = win.location.hostname || '';
} catch {
  // noop
}

// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER = (win as anyOk).IS_CONNECT_CARRIER;
export const IS_CONNECT_CARRIER_DEV_DOMAIN = devDomains.includes(hostname);
// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_TEST_DOMAIN = testDomains.includes(hostname);
// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_UAT_DOMAIN = uatDomains.includes(hostname);
// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_PROD_DOMAIN = prodDomains.includes(hostname);
export const IS_CONNECT_CARRIER_NO_UAT_NO_PROD =
  IS_CONNECT_CARRIER &&
  !IS_CONNECT_CARRIER_UAT_DOMAIN &&
  !IS_CONNECT_CARRIER_PROD_DOMAIN;

// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_CI =
  ENV_VARS.CI === 'true' || ENV_VARS.VITE_CI === 'true';
export const IS_CONNECT_CARRIER_LOCAL_DEV = MODE === 'development';
// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_CYPRESS = Boolean(
  IS_CONNECT_CARRIER && (win as anyOk).Cypress
);
// ts-unused-exports:disable-next-line
export const IS_CONNECT_CARRIER_LOCAL_CYPRESS =
  IS_CONNECT_CARRIER_CYPRESS && IS_CONNECT_CARRIER_LOCAL_DEV;
export const IS_CONNECT_CARRIER_LOCAL_OR_CI_CYPRESS =
  IS_CONNECT_CARRIER_LOCAL_CYPRESS ||
  (IS_CONNECT_CARRIER_CYPRESS && IS_CONNECT_CARRIER_CI);
