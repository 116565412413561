import { IS_LOCAL_OR_CI_CYPRESS } from '@utils/constants';
import { win } from '@utils/win';

export const TABLE_PREFERENCES_IDB_STORE = 'tablePref';
const CURR_VERSION = 1;
export const SUPPORTS_IDB = 'indexedDB' in win;

/** config to save table preferences to IndexDB */
export const idbConfig = {
  databaseName: 'mastermind',
  // In cy component tests, we have to open the indexedDB first before running the test, which seems to bump the number of the version
  version: IS_LOCAL_OR_CI_CYPRESS ? CURR_VERSION + 1 : CURR_VERSION,
  stores: [
    {
      name: TABLE_PREFERENCES_IDB_STORE,
      id: { keyPath: 'objectId', autoIncrement: false },
      indices: [
        { name: 'tableName', keyPath: 'objectId', options: { unique: true } },
        { name: 'columns', keyPath: 'columns', options: { unique: false } },
      ],
    },
  ],
};
