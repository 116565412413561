import { Icon } from '@components/Icon';
import { useFullstoryElement } from '@hooks/useFullstory';
import { useTheme } from '@hooks/useTheme';
import { FC, HTMLProps } from 'react';

export type CloseButtonProps = HTMLProps<HTMLButtonElement> & {
  fsParent?: string;
};

export const CloseButton: FC<CloseButtonProps> = (props: CloseButtonProps) => {
  const { getFsComponentProps } = useFullstoryElement();
  const { gray } = useTheme();
  return (
    <button
      css={{
        color: gray[30],
        position: 'absolute',
        right: '8px',
        fontSize: '20px',
        top: '16px',
        padding: '5px 10px',
        overflow: 'auto',
      }}
      {...props}
      data-button-close
      aria-label="Dismiss"
      type="button"
      {...getFsComponentProps({
        name: 'button-close',
        parent: props.fsParent,
        element: 'button',
        type: 'close',
      })}
    >
      <Icon i="x" size="lg" color="text" />
    </button>
  );
};
