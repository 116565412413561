import { ParameterizedRoutePath } from '@app/routes';
import { LazyRoutes, LazyRoutesArr, LoadReturn } from '@components/LazyRoutes';
import { FC } from 'react';
import { AUTH_BYPASS_ROUTES_MAP } from './util';

const routes: LazyRoutesArr = [
  {
    path: '/matches',
    load: (): LoadReturn => import('../../views/MyMatches'),
  },
  {
    path: '/trucks',
    load: (): LoadReturn => import('../../views/Capacity'),
  },
  {
    path: '/capacity',
    load: (): LoadReturn => import('../../views/Capacity'),
  },
  {
    path: '/availableroutes',
    load: (): LoadReturn => import('../../views/AvailableRoutes/redirects'),
  },
  {
    path: '/available-routes',
    load: (): LoadReturn => import('../../views/AvailableRoutes'),
  },
  {
    path: '/optimatch',
    load: (): LoadReturn => import('../../views/OptiMatch'),
  },
  {
    path: '/booking/:id/',
    load: (): LoadReturn => import('../../views/Booking'),
  },
  {
    path: '/mass-booking',
    load: (): LoadReturn => import('../../views/MassBooking'),
  },
  {
    path: '/offers',
    load: (): LoadReturn => import('../../views/OffersV2'),
  },
  {
    path: '/map',
    load: (): LoadReturn => import('../../views/StateMap'),
  },
  {
    path: '/customers',
    load: (): LoadReturn => import('../../views/Customer'),
  },
  {
    path: '/admin',
    load: (): LoadReturn => import('../../views/Admin'),
  },
  {
    path: '/accounting',
    load: (): LoadReturn => import('../../views/Accounting'),
  },
  {
    path: '/facilities',
    load: (): LoadReturn => import('../../views/Facility'),
  },
  {
    path: '/employees',
    load: (): LoadReturn => import('../../views/Employee'),
  },
  {
    path: '/groups',
    load: (): LoadReturn => import('../../views/Employee/Groups'),
  },
  {
    path: '/trailers',
    load: (): LoadReturn => import('../../views/Trailer'),
  },
  {
    path: '/powers',
    load: (): LoadReturn => import('../../views/Power'),
  },
  {
    path: '/loads',
    load: (): LoadReturn => import('../../views/Load'),
  },
  {
    path: '/load-repeat',
    load: (): LoadReturn => import('../../views/Load/RepeatOrder'),
  },
  {
    path: '/orders/create',
    load: (): LoadReturn => import('../../views/Load/CreateOrder'),
  },
  {
    path: '/orders/:id/',
    load: (): LoadReturn => import('../../views/Load/EditOrder'),
  },
  {
    path: '/plan-load',
    load: (): LoadReturn => import('../../views/Load/PlanLoad'),
  },
  {
    path: '/track',
    load: (): LoadReturn => import('../../views/Load/Track'),
  },
  {
    path: '/capacity-manager/:id/',
    load: (): LoadReturn => import('../../views/Capacity/CapacityManagerPage'),
  },
  {
    path: '/carriers',
    load: (): LoadReturn => import('../../views/Carrier'),
  },
  {
    path: '/pools',
    load: (): LoadReturn => import('../../views/Pools'),
  },
  {
    path: '/truckstop/auth/success',
    load: (): LoadReturn => import('../../views/TruckStopAuthSuccess'),
  },
  {
    path: AUTH_BYPASS_ROUTES_MAP['/__debug'],
    load: (): LoadReturn => import('../../views/Debug'),
  },
  {
    // Allow ids to be appended to debug page for sentry grouping testing
    path: AUTH_BYPASS_ROUTES_MAP['/__debug/:id/'],
    load: (): LoadReturn => import('../../views/Debug'),
  },
  {
    path: '/report',
    load: (): LoadReturn => import('../../views/Diagnostic'),
  },
  {
    path: '/CRMV2',
    load: (): LoadReturn => import('../../views/CRMV2'),
  },
  {
    path: '/CRM',
    load: (): LoadReturn => import('../../views/CRM'),
  },
  {
    path: '/portfolio',
    load: (): LoadReturn =>
      import('../../views/MainPage/BottomTabs/PortfolioInteractions'),
  },
  {
    path: '/',
    exact: true,
    load: (): LoadReturn => import('../../views/MainPage'),
  },
  // {
  //   path: '/windows',
  //   exact: true,
  //   load: (): LoadReturn => import('../../views/WindowManager'),
  // },
  {
    path: '/lanes',
    load: (): LoadReturn => import('../../views/Planning/Lane'),
  },
  {
    path: '/projects',
    load: (): LoadReturn => import('../../views/Planning/Project'),
  },
  {
    path: '/planned-network-management',
    load: (): LoadReturn =>
      import('../../views/Planning/PlannedNetworkManagement'),
  },
  {
    path: '/patterns',
    load: (): LoadReturn => import('../../views/Pattern'),
  },
  {
    path: '/drivers',
    load: (): LoadReturn => import('../../views/Driver'),
  },
  {
    path: '/carrier-commitments/:id/time-period',
    load: (): LoadReturn =>
      import(
        '../../views/Common/Commitments/CommitmentProgress/ViewCommitmentProgress'
      ),
  },
  {
    path: '/flatfile-platform',
    load: (): LoadReturn => import('../../views/DataUploadServices'),
  },
  {
    path: '*' as ParameterizedRoutePath,
    load: (): LoadReturn => import('../../views/NotFound'),
  },
];

export const AppRoutes: FC = () => (
  <LazyRoutes routes={routes} fallbackRedirect={null as anyOk} />
);
