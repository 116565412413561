import { IDB_KEY, IndexedDBConfig, win } from '@utils/win';
import { isNil, noop } from 'lodash-es';
import { idbConfig } from './config';
import { TransactionStatus, getActionsReturnType } from './types';
import { waitUntil } from './utils';

function validateStore(db: IDBDatabase, storeName: string): boolean {
  return db.objectStoreNames.contains(storeName);
}

function validateBeforeTransaction(
  db: IDBDatabase,
  storeName: string,
  reject: (reason: fixMe) => void
): void {
  if (!db) {
    reject('Queried before opening connection');
  }
  if (!validateStore(db, storeName)) {
    reject(`Store ${storeName} not found`);
  }
}

function createTransaction<T extends unknown>(kwargs: {
  db: IDBDatabase;
  dbMode: IDBTransactionMode;
  currentStore: string;
  onComplete?: (value?: T) => T | undefined;
  reject: (reason?: fixMe) => void;
  abort?: () => void;
}): IDBTransaction {
  const { db, dbMode, currentStore, onComplete, reject, abort } = kwargs;
  const tx: IDBTransaction = db.transaction(currentStore, dbMode);
  tx.onerror = (err): void => reject(err);
  tx.oncomplete = (): T | undefined => onComplete?.();
  tx.onabort = abort ?? noop;
  return tx;
}

let connectionLock = false;

export async function getConnection(
  config: IndexedDBConfig = idbConfig
): Promise<IDBDatabase> {
  if (!isNil(win?.indexedDBCurrentConnection)) {
    return win.indexedDBCurrentConnection as IDBDatabase;
  }
  if (connectionLock) {
    await waitUntil(() => !connectionLock, {
      timeout: 1000,
      errorMessage: 'Could not acquire idb connection',
    });
    return getConnection(config);
  }

  connectionLock = true;

  const hasIDB = typeof win !== 'undefined' ? win.indexedDB : null;
  let _config = config;

  if (!config) {
    throw new Error('No idb config provided');
  }

  if (!config && hasIDB) {
    await waitUntil(() => win?.[IDB_KEY]?.['init'] === 1, {
      errorMessage: 'IDB config on window not found',
    });
    _config = win[IDB_KEY]?.['config'] as IndexedDBConfig;
  }

  return new Promise<IDBDatabase>((resolve, reject) => {
    if (hasIDB) {
      const request: IDBOpenDBRequest = hasIDB.open(
        _config?.databaseName ?? 'mastermind',
        _config?.version ?? 1
      );

      request.onsuccess = (e: fixMe): void => {
        win.indexedDBCurrentConnection = e.target?.result as IDBDatabase;
        connectionLock = false;
        resolve(e.target?.result as IDBDatabase);
      };

      request.onerror = (err): void => {
        reject(err);
      };

      request.onupgradeneeded = (): void => {
        const db = request.result;
        config?.stores.forEach((s) => {
          if (!db.objectStoreNames.contains(s.name)) {
            const store = db.createObjectStore(s.name, s.id);
            s.indices.forEach((c) => {
              store.createIndex(c.name, c.keyPath, c.options);
            });
          }
        });
      };
    } else {
      reject('Failed to connect');
    }
  });
}

export function getActions<T, U = T>(
  currentStore: string
): getActionsReturnType<T, U> {
  return {
    getByID(id: string | number): Promise<T> {
      return new Promise<T>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db as IDBDatabase, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readonly',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.get(id);
            request.onsuccess = (e: anyOk): void => {
              resolve(e.target.result as T);
            };
          })
          .catch(reject);
      });
    },

    getOneByKey(
      keyPath: string,
      value: string | number
    ): Promise<T | undefined> {
      return new Promise<T | undefined>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readonly',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const index = objectStore.index(keyPath);
            const request = index.get(value);
            request.onsuccess = (e: anyOk): void => {
              resolve(e.target.result);
            };
          })
          .catch(reject);
      });
    },

    getManyByKey(keyPath: string, value: string | number): Promise<T[]> {
      return new Promise<T[]>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readonly',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const index = objectStore.index(keyPath);
            const request = index.getAll(value);
            request.onsuccess = ((
              e: Event & { target: IDBRequest & { result: T } }
            ): void => {
              resolve(e.target.result);
            }) as anyOk;
          })
          .catch(reject);
      });
    },

    getAll(): Promise<T[]> {
      return new Promise<T[]>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readonly',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.getAll();
            request.onsuccess = (e: anyOk): void => {
              resolve(e.target.result as T[]);
            };
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    add(value: T, key?: IDBValidKey): Promise<U> {
      return new Promise<U>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readwrite',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.add(value, key);
            request.onsuccess = (): void => {
              tx.commit?.();
              resolve(value as unknown as U);
            };
            request.onerror = (e: fixMe): fixMe => {
              if (
                e.target?.error?.message?.match(
                  'Key already exists in the object store.'
                )
              ) {
                return resolve(value as unknown as U);
              }
              reject(e?.target?.error);
            };
          })
          .catch(reject);
      });
    },

    update(value: T, key?: IDBValidKey): Promise<U> {
      return new Promise<U>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readwrite',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.put(value, key);
            request.onsuccess = (): void => {
              tx.commit?.();
              resolve(value as unknown as U);
            };
            request.onerror = reject;
          })
          .catch(reject);
      });
    },

    deleteByID(id: IDBValidKey): Promise<TransactionStatus> {
      return new Promise<TransactionStatus>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readwrite',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.delete(id);
            request.onsuccess = (): void => {
              tx.commit?.();
              resolve('Success');
            };
            request.onerror = reject;
          })
          .catch(reject);
      });
    },

    deleteAll(): Promise<TransactionStatus> {
      return new Promise<TransactionStatus>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readwrite',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.clear();
            request.onsuccess = (): void => {
              tx.commit?.();
              resolve('Success');
            };
            request.onerror = reject;
          })
          .catch(reject);
      });
    },

    openCursor(
      cursorCallback,
      keyRange?: IDBKeyRange
    ): Promise<IDBCursorWithValue | void> {
      return new Promise<IDBCursorWithValue | void>((resolve, reject) => {
        getConnection()
          .then((db) => {
            validateBeforeTransaction(db, currentStore, reject);
            const tx: IDBTransaction = createTransaction({
              db,
              dbMode: 'readonly',
              currentStore,
              reject,
            });
            const objectStore = tx.objectStore(currentStore);
            const request = objectStore.openCursor(keyRange);
            request.onsuccess = (e): void => {
              cursorCallback(e);
              resolve();
            };
          })
          .catch(reject);
      });
    },
  };
}
