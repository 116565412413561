import { createContext, FC, useCallback, useContext, useMemo } from 'react';

interface TabPreferencesContextManager {
  getTabPreferencesById: (tabId: string) => string;
}

const TabPreferencesContext = createContext<TabPreferencesContextManager>({
  getTabPreferencesById: () => '',
});

export const TabPreferencesProvider: FC<anyOk> = ({ children }) => {
  const getTabPreferencesById = useCallback((tabId: string): string => {
    return tabId;
  }, []);

  const value: TabPreferencesContextManager = useMemo(() => {
    return {
      getTabPreferencesById,
    };
  }, [getTabPreferencesById]);

  return (
    <TabPreferencesContext.Provider value={value}>
      {children}
    </TabPreferencesContext.Provider>
  );
};

export const useTabPreferences = (): TabPreferencesContextManager => {
  const context = useContext(TabPreferencesContext);
  if (!context) {
    throw new Error(
      'useTabPreferences must be used within a TabPreferencesProvider'
    );
  }
  return context;
};
