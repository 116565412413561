import { PureComponent } from 'react';

interface Props {
  indeterminate: boolean;
  disabled: boolean;
  id: string;
  checked: boolean;
  onClick: () => void;
  onChange: () => void;
}

export class NativeCheckbox extends PureComponent<Props> {
  static defaultProps = {
    indeterminate: false,
  };

  private checkbox: HTMLInputElement | null = null;

  componentDidMount(): void {
    this.updateDeterminateProperty();
  }

  componentDidUpdate(): void {
    this.updateDeterminateProperty();
  }

  updateDeterminateProperty(): void {
    const { indeterminate } = this.props;

    if (this.checkbox) {
      this.checkbox.indeterminate = indeterminate;
    }
  }

  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { indeterminate, ...restProps } = this.props;

    return (
      <input
        {...restProps}
        ref={(c) => {
          this.checkbox = c;
        }}
        type="checkbox"
      />
    );
  }
}
