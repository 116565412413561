import { ButtonHTMLAttributes, PureComponent, ReactNode } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  title?: string;
}

export class Button extends PureComponent<ButtonProps> {
  static defaultProps = {
    title: null,
  };

  render(): JSX.Element {
    const { children, title, ...props } = this.props;

    return (
      <button aria-label={title} title={title} type="button" {...props}>
        {children}
      </button>
    );
  }
}
