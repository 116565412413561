import { getUserData } from '@components/AuthContext';
import { getRGBFromHex, getValueFromCssVar } from '@components/Theme/util';
import { MODE } from '@env';
import { useFlagMe56819AppFullstoryEnable } from '@generated/flags/ME-56819-app-fullstory-enable';
import {
  IS_LOCAL_OR_CI_CYPRESS,
  IS_PR_PREVIEW,
  KnownDomains,
} from '@utils/constants';
import { jsonStringify } from '@utils/json';
import {
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isString,
  isUndefined,
  mapKeys,
  pickBy,
} from 'lodash-es';
import { FC, useEffect } from 'react';
import { v4 } from 'uuid';
import { BASE_TENANT_HOSTNAME } from '../../config';
import { Fullstory, win } from '../win';

const UMBRELLA_FS_ENVIRONMENT = 'o-1NFQVC-na1';

/** Do not add or modify this shape without engaging Product and Security teams first */
export interface FullstoryApprovedUserShape {
  /** The display name is the first chunk in a uuid for the user's Mastermind ID
   * ie 123e4567-e89b-12d3-a456-426614174000 => 123e4567
   * We do not want to capture PII and thus are not capturing names
   */
  displayName: string;
  userId: string;
  // extra fields
  departmentId?: Maybe<string>;
  division: Maybe<string>;
  hostname?: Maybe<string>;
  office?: Maybe<string>;
  roleId?: Maybe<string>;
  timeId?: Maybe<string>;
  title?: Maybe<string>;
  isUsingDarkModeExtension?: Maybe<boolean>;
}

export interface FullStoryType {
  /**
   * fsType is used to identify the type/location of the context menu for FullStory.
   * If you have questions about what to use for fsType, please reach out to the team running FullStory or Atlas.
   * @example "table" | "row" | "routing guide" | "trailer" | "input" | "digit" | "inputRange"
   */
  fsType?: string;
  fsName?: string;
  fsElement?: string;
  fsParent?: string;
  fsStatus?: string;
  fsOptionId?: string;
  fsAction?: string;
}

export const getFullstoryUserDisplayName = (uuid?: string): string => {
  return (uuid ?? v4()).split('-')[0] ?? 'Unknown';
};

export const isUsingDarkModeExtension = (): boolean => {
  try {
    let bodyBackgroundColor = win.getComputedStyle(
      document.body
    ).backgroundColor;
    if (bodyBackgroundColor.match('#')) {
      bodyBackgroundColor = getRGBFromHex(bodyBackgroundColor);
    }
    if (!bodyBackgroundColor?.match('rgb')) {
      return false;
    }
    const bodyBg = getRGBFromHex(getValueFromCssVar('--body-color'));
    const bodyBgThemeDark = getRGBFromHex(
      getValueFromCssVar('--body-color-theme-dark')
    );
    const htmlHasThemeDarkClass =
      document.documentElement.classList.contains('theme-dark');
    const hasThemeDarkBg =
      bodyBackgroundColor === bodyBgThemeDark && htmlHasThemeDarkClass;
    const hasDefaultBg = bodyBackgroundColor === bodyBg;
    if (!hasDefaultBg && !hasThemeDarkBg) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const FS_UNMASK = 'fs-unmask';
// ts-unused-exports:disable-next-line
export const FS_MASK = 'fs-mask';

const identify = (...args: Parameters<Fullstory['identify']>): void => {
  try {
    win.FS?.identify(...args);
  } catch (err: anyOk) {
    // eslint-disable-next-line no-console
    console.error('Error identifying with Fullstory\n' + err.message);
  }
};

export const setUserVars = (userShape: FullstoryApprovedUserShape): void => {
  win.FS?.setUserVars(userShape);
};

/** Snake case event name such as menu_item_click */
type EventName = string;

export const fsEvent = (type: EventName, obj: Record<string, anyOk>): void => {
  const mapped = mapKeys(
    pickBy(obj, (t) => !isUndefined(t)),
    (value, key) => {
      if (isString(value)) {
        return `${key}_str`;
      } else if (isNumber(value)) {
        return `${key}_int`;
      }
      return key;
    }
  );
  if (IS_LOCAL_OR_CI_CYPRESS) {
    // emit global event
    win.dispatchEvent(
      new CustomEvent('fullstory-event', { detail: { type, data: mapped } })
    );
  }
  if (MODE === 'development') {
    // eslint-disable-next-line no-console
    return console.info('Fullstory Event', { type, data: mapped });
  }
  win.FS?.event(type, mapped);
};

let HAS_INITIALIZED = false;

const tenantHostnameOrgIdMap: Partial<Record<KnownDomains, string>> &
  Record<string, string> = {
  'test.mm100.mastermindtms.com': '162Y2J',
  'dev.mm100.mastermindtms.com': 'o-1C1ZZ2-na1',
};

const INDIVIDUAL_ORG_ID: string | undefined = (
  tenantHostnameOrgIdMap as Record<string, string>
)[BASE_TENANT_HOSTNAME ?? ''];

const init = (): void => {
  const isProd = win.masteryEnv?.isProd;
  let ORG_ID = isProd ? UMBRELLA_FS_ENVIRONMENT : INDIVIDUAL_ORG_ID;
  if (IS_PR_PREVIEW) {
    ORG_ID = tenantHostnameOrgIdMap['dev.mm100.mastermindtms.com'];
  }
  if (!ORG_ID || HAS_INITIALIZED) {
    return;
  }
  const userData = getUserData();
  if (userData) {
    try {
      win.masteryFullstoryInit(ORG_ID);
      // We probably need a settimeout here because masteryFullstoryInit actually loads the js snippet which is async. We want to give it enough time to download from the network and boot before identify.
      const key = userData.key ?? v4();
      setTimeout(() => {
        identify(key, {
          displayName: getFullstoryUserDisplayName(key),
        });
      }, 4000);
      HAS_INITIALIZED = true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

interface UnmaskProps {
  /** The html element that will be rendered. Defaults to div */
  as?: string;
}

// ts-unused-exports:disable-next-line
export const Unmask: FC<UnmaskProps> = ({ children, as: asProp = 'div' }) => {
  const El = asProp as anyOk;
  return <El className={FS_UNMASK}>{children}</El>;
};

export const Mask: FC<UnmaskProps> = ({ children, as: asProp = 'div' }) => {
  const El = asProp as anyOk;
  return <El className={FS_MASK}>{children}</El>;
};

export const useFullstory = (): void => {
  const flagData = useFlagMe56819AppFullstoryEnable();
  useEffect(() => {
    if (flagData) {
      init();
    }
  }, [flagData]);
};

export enum FullstoryEventTypes {
  MASTERFIND = 'masterfind',
  LOAD_SEARCH = 'load search',
  TEST = 'Test Event',
  AI_CAPACITY_ENTRY = 'ai capacity entry',
  USER_FEEDBACK = 'user feedback',
  CUSTOMIZE_TABLE = 'customize table',
}

export enum FullStoryElements {
  CHIP_FILTER_MENU_ITEM = 'chip-filter-menu-item',
  CHIP_FILTER_SEARCH_BAR = 'chip-filter-search-bar',
  FIELD_MENU_ITEM = 'field-menu-item',
  FIELD_INPUT = 'field-input',
  FIELD_SEARCH_BAR = 'field-search-bar',
  CHIP_FILTER_BUTTON = 'chip-filter-button',
}

type Scalar = string | number | boolean | null | Date;
type ScalarOrArray = Scalar | Scalar[];

export type FsComponentProps = Record<string, Maybe<ScalarOrArray>>;

function checkNumberType(value: number, recur = false): string {
  if (Number.isInteger(value)) {
    return recur ? 'ints' : 'int';
  } else {
    return recur ? 'reals' : 'real';
  }
}

function determineType<T>(innerValue: T, recur = false): anyOk {
  if (typeof innerValue === 'string') {
    return recur ? 'strs' : 'str';
  }
  if (typeof innerValue === 'number') {
    return checkNumberType(innerValue, recur);
  }

  if (isBoolean(innerValue)) {
    return recur ? 'bools' : 'bool';
  }

  if (isDate(innerValue)) {
    return recur ? 'dates' : 'date';
  }
  if (isArray(innerValue)) {
    if (innerValue.length === 0) {
      return null;
    }
    return determineType(innerValue[0], true);
  }
  return '';
}

type FsComponentReturnType<T> = {
  [K in keyof T as `data-fs-${string & K}`]: T[K];
} & {
  'data-fs-properties-schema': string;
  'data-fs-element': string;
};

export const getFsComponentPropsUtil = <T extends FsComponentProps>(
  kwargs: T
): FsComponentReturnType<T> => {
  const schemaParts = Object.keys(kwargs)
    .filter((key) => key !== 'element')
    .reduce((acc, key) => {
      const keyExists = key in kwargs;
      const kwArgs = keyExists ? kwargs[key as keyof FsComponentProps] : '';

      // Accumulate the key-value pair in the object
      acc[`data-fs-${key.toLowerCase()}`] = {
        type: `${determineType(kwArgs)}`,
        name: key,
      };

      return acc;
    }, {} as Record<string, { type: string; name: string }>);

  const res = {
    ['data-fs-element']: String(kwargs['element'] ?? 'unknown'),
    ['data-fs-type']: kwargs['type'],
    ['data-fs-name']: kwargs['name'],
    ['data-fs-status']: kwargs['status'],
    ['data-fs-parent']: kwargs['parent'],
    ['data-fs-optionid']: kwargs['optionId'],
    ['data-fs-action']: kwargs['action'],
    ['data-fs-properties-schema']: jsonStringify(schemaParts),
  };
  return res as unknown as FsComponentReturnType<T>;
};
