import { Icon } from '@components/Icon';
import CheckboxTree, {
  CheckboxProps,
  Icons,
  Node,
} from '@components/ReactCheckboxTree';
import { NodeShape } from '@components/ReactCheckboxTree/js/shapes/nodeShape';
import { CSSObject, Global } from '@emotion/react';
import { useInputStyle } from '@hooks/useInputStyle';
import { useTheme } from '@hooks/useTheme';
import { compact, flattenDeep, isFunction, reject, xor } from 'lodash-es';
import { ReactElement, ReactNode, useState } from 'react';
import { visuallyHiddenStyle } from '../VisuallyHidden';
import { HasId } from '../shared/interfaces';

type Item = HasId<unknown>;
export type LabelRender<D extends Item> = (node: TreeNode<D>) => ReactNode;

// ts-unused-exports:disable-next-line
export interface TreeNode<D extends Item> extends NodeShape<D> {
  isChecked: boolean;
  isExpanded: boolean;
}

// ts-unused-exports:disable-next-line
export interface StringTreeNode extends Node {
  value: string;
  label: ReactNode;
  children?: StringTreeNode[];
  isChecked: boolean;
  isExpanded: boolean;
}

export const flattenNode = <D extends Item>(
  treeNode: NodeShape<D>
): NodeShape<D>[] => {
  let flattenedNode = [treeNode];

  if (treeNode.children) {
    treeNode.children.forEach((child) => {
      flattenedNode = [...flattenedNode, ...flattenNode(child)];
    });
  }

  return flattenedNode;
};

export interface UseTreePickerProps<T extends Item> {
  nodes: NodeShape<T>[];
  onChange: (kwargs: {
    checked: NodeShape<T>[];
    expanded: NodeShape<T>[];
    checkedIds: string[];
    expandedIds: string[];
  }) => void;
  multiSelect?: boolean;
  icons?: Maybe<Icons>;
  initialExpanded?: string[];
  initialChecked?: string[];
}

interface UseTreePickerReturnVal<T extends Item> {
  onCheck: (checkedStrArrRaw: string[]) => void;
  onExpand: (expandedStrArr: string[]) => void;
  checkedSet: Set<string>;
  expandedSet: Set<string>;
  nodes: NodeShape<T>[];
  icons: Maybe<Icons>;
}

export const useTreePicker = <T extends Item>(
  kwargs: UseTreePickerProps<T>
): UseTreePickerReturnVal<T> => {
  const flattened = flattenDeep(kwargs.nodes.map((node) => flattenNode(node)));
  const [state, setState] = useState<{
    checked: NodeShape<T>[];
    expanded: NodeShape<T>[];
  }>({
    checked: compact(
      (kwargs.initialChecked || []).map((str) =>
        flattened.find((obj) => obj.id === str)
      )
    ),
    expanded: compact(
      (kwargs.initialExpanded || []).map((str) =>
        flattened.find((obj) => obj.id === str)
      )
    ),
  });

  const onCheck = (checkedStrArrRaw: string[]): void => {
    setState((current) => {
      let checkedStrArr = checkedStrArrRaw;

      const currentCheckedStrArr = current.checked.map((obj) => obj.id);
      const oddItemOut =
        xor(currentCheckedStrArr, checkedStrArr)[0] || undefined;

      if (!kwargs.multiSelect) {
        if (currentCheckedStrArr.includes(oddItemOut || '')) {
          checkedStrArr = [];
        } else {
          checkedStrArr = compact([oddItemOut]);
        }
      }

      const checked = compact(
        checkedStrArr.map((id) => flattened.find((obj) => obj.id === id))
      );

      const nodeToExpand = flattened.find((obj) => obj.id === checkedStrArr[0]);
      const newToExpand = nodeToExpand?.children?.length
        ? [...checkedStrArr]
        : [];
      const currentlyExpanded = current.expanded.map((obj) => obj.id);
      let newExpandedStrArr = [
        ...new Set([...currentlyExpanded, ...newToExpand]),
      ];

      if (
        !kwargs.multiSelect &&
        currentCheckedStrArr.includes(oddItemOut || '')
      ) {
        newExpandedStrArr = reject(
          newExpandedStrArr,
          (str) => str === oddItemOut
        );
      }

      const expanded = compact(
        newExpandedStrArr.map((id) => flattened.find((obj) => obj.id === id))
      );

      const finalState = { ...current, checked, expanded };
      kwargs.onChange({
        ...finalState,
        expandedIds: compact(newExpandedStrArr),
        checkedIds: checkedStrArr,
      });
      return finalState;
    });
  };

  const onExpand = (expandedStrArr: string[]): void => {
    setState((current) => {
      const expanded = compact(
        expandedStrArr.map((id) => flattened.find((obj) => obj.id === id))
      );
      const finalState = { ...current, expanded };
      const checkedIds = current.checked.map((obj) => obj.id);
      kwargs.onChange({
        ...finalState,
        expandedIds: expandedStrArr,
        checkedIds: compact(checkedIds),
      });
      return finalState;
    });
  };

  const checkedSet = new Set(
    state.checked
      .map((c) => c.id)
      .filter((id): id is string => id !== undefined)
  );
  const expandedSet = new Set(
    state.expanded
      .map((c) => c.id)
      .filter((id): id is string => id !== undefined)
  );

  return {
    onCheck,
    onExpand,
    checkedSet,
    expandedSet,
    nodes: kwargs.nodes,
    icons: kwargs.icons,
  };
};

const convertTreeNodeToStringNode = <D extends Item>(
  node: NodeShape<D>,
  kwargs: { checkedSet: Set<string>; expandedSet: Set<string> }
): NodeShape<string> => {
  const isChecked = node.id ? kwargs.checkedSet.has(node.id) : false;
  const isExpanded = node.id ? kwargs.expandedSet.has(node.id) : false;
  const copy: StringTreeNode = {
    label: isFunction(node.label)
      ? node.label({ ...node, isChecked, isExpanded })
      : node.label,
    value: node?.id ?? '',
    isChecked,
    isExpanded,
  };
  const newChildren = node.children
    ? node.children.map((childNode) =>
        convertTreeNodeToStringNode(childNode, kwargs)
      )
    : undefined;
  return { ...copy, children: newChildren };
};

interface Props<D extends Item>
  extends Pick<CheckboxProps, 'nativeCheckboxes' | 'noCascade'>,
    UseTreePickerReturnVal<D> {
  checkedNodeStyle?: CSSObject;
  style?: CSSObject;
}

const BORDER_SIZE = 2;

export const TreePicker = <D extends Item>(props: Props<D>): ReactElement => {
  const {
    nativeCheckboxes = true,
    noCascade = true,
    checkedSet,
    expandedSet,
    checkedNodeStyle = {},
    style = {},
    nodes,
    onCheck,
    onExpand,
    icons,
    ...rest
  } = props;
  const { colors, gray } = useTheme();
  const { borderRadius } = useInputStyle();
  return (
    <div
      data-comp-treepicker
      css={{
        '.react-checkbox-tree > ol': {
          padding: 0,
        },
        '.rct-text': {
          display: 'grid',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
        },
        '.rct-collapse': {
          position: 'absolute',
          left: 0,
          top: 4,
          width: '2.5ch',
          height: '2.5ch',
          zIndex: 2,
          '&:hover + label': {
            backgroundColor: gray[95],
          },
        },
        '.rct-node': {
          position: 'relative',
        },
        '.rct-node-leaf > .rct-text > .rct-collapse': {
          ...visuallyHiddenStyle,
        },
        '.rct-node-icon': {
          display: 'none',
        },
        '.mm-online > .rct-text > label svg': {
          color: colors.success,
        },
        ol: {
          margin: 0,
          padding: '0 0 0 2ch',
          listStyleType: 'none',
        },
        '[type="checkbox"]': visuallyHiddenStyle,
        '.rct-checkbox': visuallyHiddenStyle,
        '.rct-title': {
          display: 'block',
          userSelect: 'none',
          position: 'relative',
          border: `${BORDER_SIZE}px solid transparent`,
          margin: 0,
          padding: 2,
          paddingLeft: 16,
          width: '100%',
          height: '100%',
          borderRadius: `0px ${borderRadius} ${borderRadius} 0px`,
        },
        '[type="checkbox"]:checked ~ .rct-title': {
          color: colors.primary,
          backgroundColor: `${colors.primaryLight} !important`,
          ...checkedNodeStyle,
        },
        '[type="checkbox"]:checked ~ .rct-node-icon': {
          backgroundColor: colors.primaryLight,
          ...checkedNodeStyle,
        },
        label: {
          width: '100%',
          padding: 0,
          borderRadius: borderRadius,
          '&:hover': {
            backgroundColor: gray[95],
          },
          '&:active': {
            backgroundColor: gray[90],
          },
        },
        ...style,
      }}
      {...rest}
    >
      <Global
        styles={{
          '.react-checkbox-tree': {
            '[type="checkbox"]:focus-visible ~ .rct-title': {
              border: `${BORDER_SIZE}px solid ${colors.primary}`,
              backgroundColor: gray[95],
            },
            '.rct-collapse:focus-visible + label': {
              backgroundColor: gray[95],
            },
          },
        }}
      />
      <CheckboxTree
        icons={{
          expandClose: (
            <Icon
              size="sm"
              css={{ textAlign: 'center' }}
              i="angleRight"
              color="text"
            />
          ),
          expandOpen: (
            <Icon
              size="sm"
              css={{ textAlign: 'center' }}
              i="angleDown"
              color="text"
            />
          ),
          ...icons,
        }}
        nativeCheckboxes={nativeCheckboxes}
        noCascade={noCascade}
        onCheck={onCheck}
        onExpand={onExpand}
        checked={Array.from(checkedSet)}
        expanded={Array.from(expandedSet)}
        nodes={nodes.map((node) =>
          convertTreeNodeToStringNode(node, { checkedSet, expandedSet })
        )}
      />
    </div>
  );
};
